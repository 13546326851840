/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import logo from '../assets/images/logo-cupones.png';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    TextField,
    createTheme,
    ThemeProvider,
    Button,
    Box,
    CircularProgress,
    Typography,
    Modal,
} from '@mui/material/';
import { Form } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/Login';
import { Stack } from '@mui/system';
import { yellow } from '@mui/material/colors';
import axios from 'axios';

function Login() {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#F2B807',
                contrastText: '#000',
            },
            secondary: {
                main: '#023E73',
                contrastText: '#fff',
            },
        },
    });

    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(yellow[500]),
        textTransform: 'none',
        backgroundColor: '#F2B807',
        fontSize: 20,
        fontWeight: 500,
        borderRadius: 10,
        '&:hover': {
            backgroundColor: '#DB9504',
        },
        '&.MuiButton-text': {
            color: 'white',
        },
    }));

    const handleUserChange = (element) => {
        setUser(element.target.value);
    };

    const handlePasswordChange = (element) => {
        setPassword(element.target.value);
    };

    const loginUser = async (event) => {
        try {
            setLoading(true);
            event.preventDefault();
            const resValUser = await axios.get(
                'https://demo.cuponesybeneficios.com/wp-json/wp/v2/users',
                {
                    auth: {
                        username: user,
                        password: password,
                    },
                },
            );

            if (resValUser) {
                const res = await axios.get(
                    'https://demo.cuponesybeneficios.com/wp-json/wp/v2/users',
                    {
                        auth: {
                            username: process.env.REACT_APP_WP_USER,
                            password: process.env.REACT_APP_WP_PASS,
                        },
                    },
                );
                res.data.map((u) => {
                    const mySlug = user.replace(/\s/g, '-');
                    if (mySlug.toLowerCase() === u.slug.toLowerCase()) {
                        setLoading(false);
                        navigate('/order', { state: user });
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            setShowModal(true);
            if (error.response.data.error === 'INVALID_USERNAME') {
                setErrorMsg('Usuario inválido.');
            }
            if (error.response.data.error === 'INVALID_PASSWORD') {
                setErrorMsg('Contraseña incorrecta.');
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                align="center"
                sx={{ bgcolor: '#023E73', height: '100vh' }}
                maxWidth="false">
                {!loading && (
                    <Grid container rowspacing={3} fontSize="50px">
                        <Grid xs={3} sx={{ height: '100vh' }}>
                            {' '}
                        </Grid>
                        <Grid xs={6} sx={{ height: '100vh' }}>
                            <Grid
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    '--Grid-borderWidth': '3px',
                                    paddingTop: '20vh',
                                    paddingBottom: '5vh',
                                    borderBottom:
                                        'var(--Grid-borderWidth) solid',
                                    borderColor: '#F2B807',
                                }}>
                                <img src={logo} alt="logo" width="70%" />{' '}
                            </Grid>
                            <Grid
                                xs={12}
                                textAlign="center"
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 40,
                                    fontWeight: 600,
                                    '--Grid-borderWidth': '3px',
                                    borderBottom:
                                        'var(--Grid-borderWidth) solid',
                                    borderColor: '#F2B807',
                                }}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center">
                                    <LoginIcon
                                        sx={{
                                            color: '#F2B807',
                                            fontSize: 50,
                                            paddingTop: '1vh',
                                            paddingBottom: '1vh',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            paddingTop: '1vh',
                                            paddingBottom: '1vh',
                                        }}>
                                        Login
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid
                                xs={12}
                                justifyContent="center"
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 30,
                                    fontWeight: 400,
                                    paddingTop: '4vh',
                                }}>
                                <Form onSubmit={loginUser}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center">
                                        <Box sx={{ paddingLeft: '3.3vw' }}>
                                            Usuario:
                                        </Box>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="Usuario"
                                            color="primary"
                                            focused
                                            sx={{
                                                input: { color: 'white' },
                                                paddingLeft: '2vw',
                                            }}
                                            onChange={handleUserChange}
                                            onKeyPress={(e) =>
                                                e.key === 'Enter'
                                            }
                                        />
                                    </Stack>
                                </Form>
                            </Grid>
                            <Grid
                                xs={12}
                                justifyContent="center"
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 30,
                                    fontWeight: 400,
                                    paddingTop: '4vh',
                                }}>
                                <Form onSubmit={loginUser}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center">
                                        <Box>Contraseña:</Box>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="Contraseña"
                                            color="primary"
                                            focused
                                            sx={{
                                                input: { color: 'white' },
                                                paddingLeft: '2vw',
                                            }}
                                            onChange={handlePasswordChange}
                                            type="password"
                                            onKeyPress={(e) =>
                                                e.key === 'Enter'
                                            }
                                        />
                                    </Stack>
                                </Form>
                            </Grid>
                            <Grid xs={12} sx={{ marginTop: '4vh' }}>
                                <CustomButton
                                    variant="contained"
                                    onClick={loginUser}>
                                    Ingresar
                                </CustomButton>
                            </Grid>
                        </Grid>
                        <Grid xs={3} sx={{ height: '100vh' }}></Grid>
                    </Grid>
                )}
                {loading && (
                    <>
                        <CircularProgress
                            size="6vh"
                            sx={{ marginTop: '47vh' }}
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            color="primary">
                            Cargando...
                        </Typography>
                    </>
                )}
                {showModal && (
                    <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '30vw',
                                bgcolor: '#023E73',
                                boxShadow: 24,
                                p: 4,
                                textAlign: 'center',
                            }}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    color: '#FEFEFE',
                                }}>
                                ¡Oh no!
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2, mb: 2, color: '#FEFEFE' }}>
                                {errorMsg}
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Container>
            <Outlet />
        </ThemeProvider>
    );
}

export default Login;
