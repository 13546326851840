import * as React from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/images/logo-cupones.png';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Stack,
    TextField,
    createTheme,
    ThemeProvider,
    Button,
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    TableCell,
    Paper,
    TableSortLabel,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    TablePagination,
    Modal,
    CircularProgress,
} from '@mui/material/';

import Handshake from '@mui/icons-material/Handshake';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Barcode from 'react-barcode/lib/react-barcode';

function createData(codCupon, descripcion, redimir, fecha, hora, sucursal) {
    return {
        codCupon,
        descripcion,
        redimir,
        fecha,
        hora,
        sucursal,
    };
}

const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[500]),
    textTransform: 'none',
    backgroundColor: '#F2B807',
    fontSize: 20,
    fontWeight: 500,
    borderRadius: 10,
    '&:hover': {
        backgroundColor: '#DB9504',
    },
    '&.MuiButton-text': {
        color: 'white',
    },
}));

/* const rows = [
    createData(1234, 'Descripción 1', 1, 67, 4.3, 'Sucursal 1'),
    createData(5678, 'Descripción 2', 2, 51, 4.9, 'Sucursal 2'),
] */

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'codCupon',
        numeric: true,
        disablePadding: true,
        label: 'Cod. Cupón',
    },
    {
        id: 'descripcion',
        numeric: false,
        disablePadding: false,
        label: 'Descripción',
    },
    {
        id: 'redimir',
        numeric: true,
        disablePadding: false,
        label: 'Redimir',
    },
    {
        id: 'fecha',
        numeric: true,
        disablePadding: false,
        label: 'Fecha',
    },
    {
        id: 'hora',
        numeric: true,
        disablePadding: false,
        label: 'Hora',
    },
    {
        id: 'sucursal',
        numeric: false,
        disablePadding: false,
        label: 'Sucursal',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={true}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{ paddingLeft: '10%' }}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: 'secondary',
                }),
            }}>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div">
                Cupones disponibles
            </Typography>
            <Tooltip title="Filter list">
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function Dashboard(props) {
    const location = useLocation();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#F2B807',
                contrastText: '#000',
            },
            secondary: {
                main: '#023E73',
                contrastText: '#fff',
            },
        },
    });

    const [rows, setRows] = useState([]);
    const [orders, setOrders] = useState(location.state.order);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        location.state.products.map((i) => {
            i.myCount = 0;
        });
        setRows(location.state.products);
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get(
                `https://demo.cuponesybeneficios.com/wp-json/wc/v3/orders/${orders.id}`,
                {
                    auth: {
                        username: process.env.REACT_APP_WC_USER,
                        password: process.env.REACT_APP_WC_PASS,
                    },
                },
            )
            .then((res) => {
                setLoading(false);
                setOrders(res.data);
            });
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleRemoveItem = (event) => {
        const newRows = rows.map((r) => {
            if (event.currentTarget.id === r.id.toString() && r.myCount > 0) {
                r.myCount--;
            } else if (
                event.currentTarget.id === r.id.toString() &&
                r.myCount <= 0
            ) {
                console.log('No reste');
            }
            return r;
        });
        setRows(newRows);
    };

    const handelAddItem = (event) => {
        const newRows = rows.map((r) => {
            orders.line_items.map((i) => {
                if (r.id === i.product_id) {
                    if (
                        event.currentTarget.id === r.id.toString() &&
                        r.myCount < i.quantity
                    ) {
                        r.myCount++;
                    } else if (
                        event.currentTarget.id === r.id.toString() &&
                        r.myCount >= r.quantity
                    ) {
                        console.log('No sume');
                    }
                }
                return r;
            });
            return r;
        });

        setRows(newRows);
    };

    const defineQuantity = (rowId) => {
        for (const i of orders.line_items) {
            if (rowId === i.product_id) {
                return ' ' + i.quantity;
            }
        }
    };

    const handleRedimir = async () => {
        try {
            const newLineItems = [];
            for (const item of orders.line_items) {
                for (const product of rows) {
                    if (item.product_id === product.id) {
                        if (product.myCount > 0) {
                            item.quantity = item.quantity - product.myCount;
                            item.parent_name = 'null';
                            newLineItems.push(item);
                        }
                    }
                }
            }
            const data = {
                line_items: newLineItems,
            };

            if (data.line_items.length > 0) {
                setShowModal(true);
                setLoading(true);
                const res = await axios.put(
                    `https://demo.cuponesybeneficios.com/wp-json/wc/v3/orders/${orders.id}`,
                    data,
                    {
                        auth: {
                            username: process.env.REACT_APP_WC_USER,
                            password: process.env.REACT_APP_WC_PASS,
                        },
                    },
                );

                if (res.data) {
                    const resOrder = await axios.get(
                        `https://demo.cuponesybeneficios.com/wp-json/wc/v3/orders/${orders.id}`,
                        {
                            auth: {
                                username: process.env.REACT_APP_WC_USER,
                                password: process.env.REACT_APP_WC_PASS,
                            },
                        },
                    );
                    if (resOrder) {
                        for (const product of rows) {
                            product.myCount = 0;
                        }
                        setLoading(false);
                        setOrders(resOrder.data);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleModalClose = () => {
        if (orders.length === 0) {
            navigate('/order');
        }
        setShowModal(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                align="center"
                sx={{ bgcolor: '#023E73', height: '100vh' }}
                maxWidth="false">
                <Grid container rowspacing={3} fontSize="50px">
                    <Grid
                        xs={12}
                        md={3}
                        sx={{
                            paddingRight: '2vw',
                        }}>
                        <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                '--Grid-borderWidth': '3px',
                                paddingTop: '5vh',
                                paddingBottom: '3vh',
                                borderBottom: 'var(--Grid-borderWidth) solid',
                                borderColor: '#F2B807',
                            }}>
                            <img src={logo} alt="logo" width="70%" />{' '}
                        </Grid>
                        <Grid
                            textAlign="center"
                            sx={{
                                color: '#FEFEFE',
                                fontSize: 20,
                                fontWeight: 400,
                                '--Grid-borderWidth': '3px',
                                borderBottom: 'var(--Grid-borderWidth) solid',
                                borderColor: '#F2B807',
                            }}>
                            {' '}
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="center">
                                <Handshake
                                    sx={{
                                        color: '#F2B807',
                                        fontSize: 20,
                                        paddingTop: '1vh',
                                        paddingBottom: '1vh',
                                    }}
                                />{' '}
                                <Box
                                    sx={{
                                        paddingTop: '1vh',
                                        paddingBottom: '1vh',
                                    }}>
                                    Portal Aliados
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                paddingTop: '2vh',
                                paddingBottom: '1vh',
                            }}>
                            <img
                                src={location.state.logoAliado}
                                alt="logo"
                                width="70%"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        md={9}
                        sx={{
                            bgcolor: '#E6E7E9',
                            marginTop: '5vh',
                            color: '#023E73',
                            fontSize: 25,
                            fontWeight: 600,
                        }}>
                        {' '}
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                paddingLeft: '2vw',
                                paddingTop: '1vh',
                                paddingBottom: '1vh',
                            }}>
                            <Box
                                sx={{
                                    paddingTop: '2vh',
                                    paddingBottom: '1vh',
                                }}>
                                Pedido No.
                            </Box>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                value={orders.id}
                                color="secondary"
                                sx={{
                                    paddingTop: '1vh',
                                    paddingLeft: '1vw',
                                }}
                            />{' '}
                            <Box
                                sx={{
                                    paddingTop: '2vh',
                                    paddingBottom: '1vh',
                                }}>
                                Nombre Cliente
                            </Box>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                value={
                                    orders.billing.first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                    orders.billing.first_name
                                        .slice(1)
                                        .toLowerCase() +
                                    ' ' +
                                    orders.billing.last_name
                                        .charAt(0)
                                        .toUpperCase() +
                                    orders.billing.last_name
                                        .slice(1)
                                        .toLowerCase()
                                }
                                color="secondary"
                                sx={{
                                    paddingTop: '1vh',
                                    paddingLeft: '1vw',
                                    paddingRight: '1vw',
                                }}
                            />
                        </Stack>
                        <Box sx={{ width: '100%' }}>
                            {!loading && (
                                <>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <EnhancedTableToolbar />
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size="small">
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={
                                                        handleRequestSort
                                                    }
                                                    rowCount={rows.length}
                                                />
                                                <TableBody>
                                                    {stableSort(
                                                        rows,
                                                        getComparator(
                                                            order,
                                                            orderBy,
                                                        ),
                                                    )
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                                rowsPerPage,
                                                        )
                                                        .map((row, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={
                                                                        row.id
                                                                    }>
                                                                    <TableCell
                                                                        component="th"
                                                                        id={
                                                                            labelId
                                                                        }
                                                                        scope="row"
                                                                        align="center">
                                                                        {row.id}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            row.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            style={{
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}>
                                                                            <IconButton
                                                                                aria-label="remove"
                                                                                id={
                                                                                    row.id
                                                                                }
                                                                                onClick={
                                                                                    handleRemoveItem
                                                                                }>
                                                                                <RemoveIcon />
                                                                            </IconButton>
                                                                            <Box
                                                                                sx={{
                                                                                    paddingTop:
                                                                                        '1vh',
                                                                                    paddingBottom:
                                                                                        '1vh',
                                                                                }}>
                                                                                {row.myCount +
                                                                                    ' '}
                                                                                de
                                                                                {defineQuantity(
                                                                                    row.id,
                                                                                )}
                                                                            </Box>

                                                                            <IconButton
                                                                                aria-label="add"
                                                                                id={
                                                                                    row.id
                                                                                }
                                                                                onClick={
                                                                                    handelAddItem
                                                                                }>
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            orders.date_created.split(
                                                                                'T',
                                                                            )[0]
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            orders.date_created.split(
                                                                                'T',
                                                                            )[1]
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {
                                                                            location
                                                                                .state
                                                                                .worksite
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height:
                                                                    33 *
                                                                    emptyRows,
                                                            }}>
                                                            <TableCell
                                                                colSpan={6}
                                                            />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={
                                                handleChangeRowsPerPage
                                            }
                                        />
                                    </Paper>
                                    <CustomButton
                                        variant="contained"
                                        sx={{ margin: '10px' }}
                                        onClick={handleRedimir}>
                                        Redimir
                                    </CustomButton>
                                </>
                            )}
                            {loading && (
                                <>
                                    <CircularProgress size="6%" />
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="primary">
                                        Cargando...
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <a
                    href="https://api.whatsapp.com/send/?phone=50761348442&text&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: '#F2B807' }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        textAlign="center"
                        sx={{
                            marginTop: '30vh',
                            marginRight: '1vw',
                        }}>
                        <div>
                            <SupportAgentIcon
                                sx={{ fontSize: '7vh' }}></SupportAgentIcon>
                            <div>
                                <b>¿Necesitas ayuda?</b>
                                <p>Llama al 6134-8442</p>
                            </div>
                        </div>
                    </Stack>
                </a>

                {showModal && (
                    <Modal
                        open={showModal}
                        onClose={(reason) => {}}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '30vw',
                                bgcolor: '#023E73',
                                boxShadow: 24,
                                p: 4,
                                textAlign: 'center',
                            }}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    color: '#FEFEFE',
                                }}>
                                Código(s) para facturar
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2, mb: 2 }}>
                                {!loading && (
                                    //<img src={logo} alt="logo" width="70%" />
                                    <Barcode value="XYTE1234567" />
                                )}
                                {loading && (
                                    <>
                                        <CircularProgress size="6%" />
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="primary">
                                            Cargando...
                                        </Typography>
                                    </>
                                )}
                            </Typography>
                            {!loading && (
                                <CustomButton
                                    variant="contained"
                                    sx={{
                                        margin: '10px',
                                    }}
                                    onClick={handleModalClose}>
                                    Cerrar
                                </CustomButton>
                            )}
                        </Box>
                    </Modal>
                )}
            </Container>
        </ThemeProvider>
    );
}

export default Dashboard;
