import React, { useState, Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import ReactDOM from 'react-dom'
import Login from './Login/login'
import Order from './Order/order'
import Dashboard from './Dashboard/dashboard'

const App = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/order" element={<Order />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App

//const rootElement = document.getElementById("root");
//ReactDOM.render(<App />, rootElement);
