/* eslint-disable array-callback-return */
import logo from '../assets/images/logo-cupones.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
    Container,
    Grid,
    TextField,
    createTheme,
    ThemeProvider,
    Button,
    Box,
    Stack,
    Divider,
    CircularProgress,
    Typography,
    Modal,
} from '@mui/material/';
import { Form } from 'react-bootstrap';
import Handshake from '@mui/icons-material/Handshake';
import { styled } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import axios from 'axios';
import { useEffect, useState } from 'react';
import './order.css';

function Order() {
    const location = useLocation();
    const [numberOrder, setNumberOrder] = useState('');
    const navigate = useNavigate();
    const [logoAliado, setLogoAliado] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const aliadosImages = [
        {
            name: 'Papa Johns',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/logo-papajohns.png',
        },
        {
            name: 'Summit Rain Forest',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/summit-rainforest.jpeg',
        },
        {
            name: 'Marco Aldany',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/LOGO_MARCO_ALDANY_NEGRO.png',
        },
        {
            name: 'Camping Resort',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/slider2/camping.png',
        },
        {
            name: 'Roadsters Diner',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/ROADSTERS_DINER.png',
        },
        {
            name: 'Ttaram Spa',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/TTARAM-1.png',
        },
        {
            name: 'Playa Blanca',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/LOGO-PLAYA-BLANCA_BLANCO.png',
        },
        {
            name: 'Albrook',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2021/11/bowling-PNG.png',
        },
        {
            name: 'Topcar Wash',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/topcar-wash.jpeg',
        },
        {
            name: 'Tambor',
            url: 'https://demo.cuponesybeneficios.com/wp-content/uploads/2022/02/Capa-1.png',
        },
    ];

    useEffect(() => {
        aliadosImages.map((a) => {
            if (location.state.toLowerCase().includes(a.name.toLowerCase())) {
                setLogoAliado(a.url);
            }
        });
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#F2B807',
                contrastText: '#000',
            },
            secondary: {
                main: '#023E73',
                contrastText: '#fff',
            },
        },
    });

    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(yellow[500]),
        textTransform: 'none',
        backgroundColor: '#F2B807',
        fontSize: 20,
        fontWeight: 500,
        borderRadius: 10,
        '&:hover': {
            backgroundColor: '#DB9504',
        },
        '&.MuiButton-text': {
            color: 'white',
        },
    }));

    const handleNumberOrderChange = (element) => {
        setNumberOrder(element.target.value);
    };

    const searchOrder = async (event) => {
        try {
            setLoading(true);
            event.preventDefault();
            const res = await axios.get(
                `https://demo.cuponesybeneficios.com/wp-json/wc/v3/orders/${numberOrder}`,
                {
                    auth: {
                        username: process.env.REACT_APP_WC_USER,
                        password: process.env.REACT_APP_WC_PASS,
                    },
                },
            );
            if (res) {
                let auxArray = [];
                for (const item of res.data.line_items) {
                    const resProduct = await axios.get(
                        `https://demo.cuponesybeneficios.com/wp-json/wc/v3/products/${item.product_id}`,
                        {
                            auth: {
                                username: process.env.REACT_APP_WC_USER,
                                password: process.env.REACT_APP_WC_PASS,
                            },
                        },
                    );

                    resProduct.data.categories.map((category) => {
                        if (
                            location.state
                                .replace(/\s/g, '')
                                .toLowerCase()
                                .includes(
                                    category.name
                                        .replace(/\s/g, '')
                                        .toLowerCase(),
                                )
                        ) {
                            auxArray.push(resProduct.data);
                        }
                    });
                }
                if (auxArray.length !== 0) {
                    setLoading(false);
                    navigate('/dashboard', {
                        state: {
                            products: auxArray,
                            order: res.data,
                            logoAliado,
                            worksite: location.state.split(' ')[2],
                        },
                    });
                } else {
                    setLoading(false);
                    setShowModal(true);
                    setErrorMsg(
                        `No hay productos de ${location.state} asociados a este número de pedido. Escribe un nuevo número e intenta nuevamente.`,
                    );
                }
            }
        } catch (error) {
            setLoading(false);
            setShowModal(true);
            if (
                error.response.data.code ===
                'woocommerce_rest_shop_order_invalid_id'
            ) {
                setErrorMsg('Número de pedido inválido.');
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                align="center"
                sx={{ bgcolor: '#023E73', height: '100vh' }}
                maxWidth="false">
                {!loading && (
                    <Grid container rowspacing={3} fontSize="50px">
                        <Grid xs={3} sx={{ height: '100vh' }}>
                            {' '}
                        </Grid>
                        <Grid xs={6} sx={{ height: '100vh' }}>
                            <Grid
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                sx={{
                                    '--Grid-borderWidth': '3px',
                                    paddingTop: '15vh',
                                    paddingBottom: '5vh',
                                    borderBottom:
                                        'var(--Grid-borderWidth) solid',
                                    borderColor: '#F2B807',
                                }}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-evenly"
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                borderColor: '#F2B807',
                                            }}
                                            flexItem
                                        />
                                    }>
                                    <div className="logo-cupones">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            width="100%"
                                        />
                                    </div>
                                    <div className="logo-aliado">
                                        <img
                                            src={logoAliado}
                                            alt="logo"
                                            width="60%"
                                        />
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid
                                xs={12}
                                textAlign="center"
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 40,
                                    fontWeight: 600,
                                    '--Grid-borderWidth': '3px',
                                    borderBottom:
                                        'var(--Grid-borderWidth) solid',
                                    borderColor: '#F2B807',
                                }}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center">
                                    <Handshake
                                        sx={{
                                            color: '#F2B807',
                                            fontSize: 50,
                                            paddingTop: '1vh',
                                            paddingBottom: '1vh',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            paddingTop: '1vh',
                                            paddingBottom: '1vh',
                                        }}>
                                        Portal Aliados
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid
                                xs={12}
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 30,
                                    fontWeight: 200,
                                    paddingBottom: '5vh',
                                    paddingTop: '2vh',
                                }}>
                                {' '}
                                Por favor ingresa el código de compra{' '}
                            </Grid>
                            <Grid
                                xs={12}
                                justifyContent="center"
                                sx={{
                                    color: '#FEFEFE',
                                    fontSize: 30,
                                    fontWeight: 400,
                                }}>
                                <Form onSubmit={searchOrder}>
                                    <TextField
                                        className="mui-number-field"
                                        required
                                        id="outlined-required"
                                        placeholder="Código de compra"
                                        color="primary"
                                        type={'number'}
                                        focused
                                        sx={{
                                            input: { color: 'white' },
                                            paddingBottom: '2vh',
                                            paddingLeft: '1vw',
                                        }}
                                        onChange={handleNumberOrderChange}
                                        onKeyPress={(e) => e.key === 'Enter'}
                                    />{' '}
                                </Form>
                            </Grid>
                            <Grid
                                xs={12}
                                sx={{
                                    textAlign: 'center',
                                }}>
                                <CustomButton
                                    variant="contained"
                                    onClick={searchOrder}>
                                    Buscar
                                </CustomButton>
                            </Grid>
                        </Grid>
                        <Grid xs={3} sx={{ height: '100vh' }}>
                            {' '}
                        </Grid>
                    </Grid>
                )}
                {loading && (
                    <>
                        <CircularProgress
                            size="6vh"
                            sx={{ marginTop: '47vh' }}
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            color="primary">
                            Cargando...
                        </Typography>
                    </>
                )}
                {showModal && (
                    <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '30vw',
                                bgcolor: '#023E73',
                                boxShadow: 24,
                                p: 4,
                                textAlign: 'center',
                            }}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    color: '#FEFEFE',
                                }}>
                                ¡Oh no!
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2, mb: 2, color: '#FEFEFE' }}>
                                {errorMsg}
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Container>
            <Outlet />
        </ThemeProvider>
    );
}

export default Order;
